<template>
  <div>
    <div class="d-flex align-center justify-center flex-column">
      <div class="parent_img">
        <v-img src="../../assets/img/photo Login.png" class="img_login">
        </v-img>
      </div>
      <v-card outlined class="mt-10 pa-3 rounded-lg form">
        <div>
          <h4>Password</h4>
          <v-text-field
            v-model="password"
            :type="show ? 'text' : 'password'"
            hide-details
            dense
            background-color="rgba(176, 186, 195, 0.4)"
            flat
            solo
            class="txt_input rounded-lg"
            placeholder="Masukkan kata sandi baru anda disini"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :error="errors"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <h4>Confirm Password</h4>
          <v-text-field
            v-model="confirm_password"
            type="password"
            hide-details="auto"
            dense
            background-color="rgba(176, 186, 195, 0.4)"
            flat
            solo
            class="txt_input rounded-lg"
            :rules="confirmPasswordRules"
            placeholder="Masukkan kata sandi baru anda disini"
          ></v-text-field>
        </div>
        <v-btn
          @click="resetPassword"
          depressed
          block
          dark
          class="rounded-lg mt-3 text-capitalize bg_color1"
        >
          <h4 v-if="!loading">Reset Password</h4>
          <h4 v-if="loading">Loading...</h4>
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "resetPassword",
  data() {
    return {
      show: false,
      password: "",
      confirm_password: "",
      loading: false,
      confirmPasswordRules: [
        (value) => !!value || "Type password again",
        (value) => value === this.password || "The password doesn't match.",
      ],
      errors: null,
    };
  },
  methods: {
    // fungsi reset password
    resetPassword() {
      console.log("ddd");
      this.loading = true;
      this.errors = null;
      if (this.password === this.confirm_password) {
        let data = {
          body: {
            token: this.$route.params.token,
            email: this.$route.params.email,
            password: this.password,
            password_confirmation: this.confirm_password,
          },
          path: `auth/password/reset`,
        };
        this.$store
          .dispatch("auth/resetPassword", data)
          .then((data) => {
            console.log(data);
            this.loading = false;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          })
          .catch((e) => {
            var key = Object.keys(e.errors);
            var values = Object.values(e.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$toast.error(`Kata sandi tidak sama!`);
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 396px) {
  .img_login {
    width: 100%;
    margin-top: 30px;
    object-fit: contain;
  }
  .parent_img {
    width: 50%;
    margin-top: 10px;
  }
  .form {
    width: 70%;
  }
}
@media (max-width: 575px) {
  .img_login {
    width: 100%;
    margin-top: 30px;
    object-fit: contain;
  }
  .parent_img {
    width: 60%;
    margin-top: 10px;
  }
  .form {
    width: 90%;
  }
}
@media (min-width: 576px) {
  .img_login {
    width: 100%;
    margin-top: 30px;
    object-fit: contain;
  }
  .parent_img {
    width: 65%;
    margin-top: 10px;
  }
  .form {
    width: 90%;
  }
}
@media (min-width: 768px) {
  .img_login {
    width: 100%;
    margin-top: 30px;
    object-fit: contain;
  }
  .parent_img {
    width: 60%;
    margin-top: 10px;
  }
  .form {
    width: 70%;
  }
}
@media (min-width: 960px) {
  .img_login {
    width: 100%;
    margin-top: 30px;
    object-fit: contain;
  }
  .parent_img {
    width: 45%;
    margin-top: 10px;
  }
  .form {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .img_login {
    margin-top: 30px;
    width: 100%;
    object-fit: contain;
  }
  .parent_img {
    width: 45%;
    margin-top: 10px;
  }
  .form {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .img_login {
    margin-top: 30px;
    width: 100%;
    object-fit: contain;
  }
  .parent_img {
    width: 30%;
    margin-top: 10px;
  }
  .form {
    width: 40%;
  }
}
@media (min-width: 1400px) {
  .img_login {
    margin-top: 30px;
    width: 100%;
    object-fit: contain;
  }
  .parent_img {
    width: 25%;
    margin-top: 10px;
  }
  .form {
    width: 30%;
  }
}
</style>
